import { useReducer } from "react";

const defaultUseInputState = {
  enteredValue: "",
  enteredValueIsTouch: false,
};

const useInputReducer = (state, action) => {
  if (action.type == "onChange") {
    return {
      enteredValue: action.value,
      enteredValueIsTouch: state.enteredValueIsTouch,
    };
  }
  if (action.type == "onTouch") {
    return {
      enteredValue: state.enteredValue,
      enteredValueIsTouch: action.value,
    };
  }
  if (action.type == "onReset") {
    return defaultUseInputState;
  }
  return defaultUseInputState;
};


const useInput = (valueValidateFunc) => {
  const [useInputState, dispatchUseInputAction] = useReducer(
    useInputReducer,
    defaultUseInputState
  );

  const valueIsValid = valueValidateFunc(useInputState.enteredValue);
  const hasError = !valueIsValid && useInputState.enteredValueIsTouch;

  const valueChangeHandler = (event) => {
    dispatchUseInputAction({ type: "onChange", value: event.target.value });
  };

  const valueBlurHandler = (event) => {
    dispatchUseInputAction({ type: "onTouch", value: true });
  };

  const valueResetHandler = () => {
    dispatchUseInputAction({ type: "onReset" });
  };

  return {
    value: useInputState.enteredValue,
    hasError,
    valueChangeHandler,
    valueBlurHandler,
    valueResetHandler,
  };
};

export default useInput;

import { useCallback, useState } from "react";

const useHttp = () => {
  const [isLoading, setIsLoading] = useState(false);

  const sendRequest = useCallback(async (requestConfig, callBack) => {
    try {
      setIsLoading(true);
      const respond = await fetch(requestConfig.url, {
        method: requestConfig.method ? requestConfig.method : "GET",
        headers: requestConfig.headers ? requestConfig.headers : {},
        body: requestConfig.body ? JSON.stringify(requestConfig.body) : null,
      });
      if (!respond.ok) {
        throw new Error("Something went wrong");
      }
      callBack({ status: true, data: await respond.json() });
    } catch (error) {
      callBack({ status: false, data: error.message });
    }
    setIsLoading(false);
  }, []);

  return {
    isLoading,
    sendRequest,
  };
};

export default useHttp;

import React from "react";
import { Form } from "react-bootstrap";
import useHttp from "../../hooks/use-http";
import useInput from "../../hooks/use-input";
import { Input, TextArea, Button } from "../Core";
import classes from "./ContactForm.module.css";

const isNotEmpty = (value) => value.trim() !== ""; //true
const isEmailValid = (value) => /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value);

const ContactForm = ({ theme = "dark", ...rest }) => {
  const { isLoading, sendRequest: createInquiry } = useHttp();

  const {
    value: enteredName,
    hasError: enteredNameHasError,
    valueChangeHandler: enteredNameChangeHandler,
    valueBlurHandler: enteredNameBlurHandler,
    valueResetHandler: enteredNameResetHandler,
  } = useInput(isNotEmpty);

  const {
    value: enteredEmailAddress,
    hasError: enteredEmailAddressHasError,
    valueChangeHandler: enteredEmailAddressChangeHandler,
    valueBlurHandler: enteredEmailAddressBlurHandler,
    valueResetHandler: enteredEmailAddressResetHandler,
  } = useInput(isNotEmpty);

  const {
    value: enteredSubject,
    hasError: enteredSubjectHasError,
    valueChangeHandler: enteredSubjectChangeHandler,
    valueBlurHandler: enteredSubjectBlurHandler,
    valueResetHandler: enteredSubjectResetHandler,
  } = useInput(isNotEmpty);

  const {
    value: enteredMessage,
    hasError: enteredMessageHasError,
    valueChangeHandler: enteredMessageChangeHandler,
    valueBlurHandler: enteredMessageBlurHandler,
    valueResetHandler: enteredMessageResetHandler,
  } = useInput(isNotEmpty);

  const onSubmitHandler = (event) => {
    event.preventDefault();
    const isFormValid =
      enteredName && enteredEmailAddress && enteredSubject && enteredMessage;
    if (!isFormValid) {
      enteredNameBlurHandler();
      enteredEmailAddressBlurHandler();
      enteredSubjectBlurHandler();
      enteredMessageBlurHandler();
      return;
    }
    sendEmailToQueue(
      enteredName,
      enteredEmailAddress,
      enteredSubject,
      enteredMessage
    );
  };

  const formReset = () => {
    enteredNameResetHandler();
    enteredEmailAddressResetHandler();
    enteredSubjectResetHandler();
    enteredMessageResetHandler();
  };

  const sendEmailToQueue = (...inquryData) => {
    createInquiry(
      {
        url:
          "https://mulithajayawardanacom-default-rtdb.firebaseio.com/email-queue.json",
        method: "POST",
        body: {
          userName: inquryData[0],
          userEmailAddress: inquryData[1],
          subject: inquryData[2],
          message: inquryData[3],
        },
      },
      (data) => {
        if (data.status) {
          formReset();
        } else {
        }
      }
    );
  };

  return (
    <Form onSubmit={onSubmitHandler}>
      <div className={enteredNameHasError ? `mt-4 ${classes.invalid}` : `mt-4`}>
        <Input
          type="text"
          placeholder="Your name"
          name="new-text"
          value={enteredName}
          onBlur={enteredNameBlurHandler}
          onChange={enteredNameChangeHandler}
          className={enteredNameHasError ? `invalid` : ""}
        />
        {enteredNameHasError && <p> Opps.. Your Name Cannot Blank. </p>}
      </div>

      <div
        className={
          enteredEmailAddressHasError ? `mt-4 ${classes.invalid}` : `mt-4`
        }
      >
        <Input
          type="text"
          placeholder="Email address"
          name="new-text"
          value={enteredEmailAddress}
          onBlur={enteredEmailAddressBlurHandler}
          onChange={enteredEmailAddressChangeHandler}
          className={enteredEmailAddressHasError ? `invalid` : ""}
        />
        {enteredEmailAddressHasError && (
          <p> Opps.. Email Address Cannot Blank. </p>
        )}
      </div>

      <div
        className={enteredSubjectHasError ? `mt-4 ${classes.invalid}` : `mt-4`}
      >
        <Input
          type="text"
          placeholder="Subject"
          name="new-text"
          value={enteredSubject}
          onBlur={enteredSubjectBlurHandler}
          onChange={enteredSubjectChangeHandler}
          className={enteredSubjectHasError ? `invalid` : ""}
        />
        {enteredSubjectHasError && <p> Opps.. Subject Cannot Blank. </p>}
      </div>

      <div
        className={enteredMessageHasError ? `mt-4 ${classes.invalid}` : `mt-4`}
      >
        <TextArea
          rows={4}
          placeholder="Write your message.."
          name="new-text"
          value={enteredMessage}
          onBlur={enteredMessageBlurHandler}
          onChange={enteredMessageChangeHandler}
          className={
            enteredMessageHasError ? `form-control invalid` : "form-control"
          }
        />
        {enteredMessageHasError && <p> Opps.. Message Cannot Blank. </p>}
      </div>

      <div className="mt-4 mt-lg-5">
        <Button arrowRight variant="primary" type="submit">
          SEND
        </Button>
      </div>
    </Form>
  );
};

export default ContactForm;
